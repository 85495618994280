import React, { Component } from "react";
import { Router } from "@reach/router";

import Structure from "shared/styles/Structure.module.scss";
import "shared/styles/Typography.module.scss";

import Home from "pages/Home.jsx";

import Picture from "pages/Picture.jsx";

import CanYouPicture from "pages/CanYouPicture.jsx";
import Legal from "pages/Legal.jsx";

import firebase from "./firebase";
import moment from "moment";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingId: "test-financialWellness",
      databaseKey: null,
      lastAction: null,
      clickstream: [],
      progress: {}
    };
  }

  setDatabaseRef() {
    const newRef = firebase
      .database()
      .ref()
      .push();
    this.setState({
      databaseKey: newRef.key
    });
    console.log(newRef.key);
  }

  setMarketingId(id) {
    if (id) {
      this.setState({
        marketingId: id
      });
    } else {
      this.setState({
        marketingId: "No ID found"
      });
    }
  }

  updateClickstream = (name, action, cta = false) => {
    let lastAction = action ? action : this.state.lastAction;
    const timeStr = moment().format("YYYY-MM-DDTHH:mm:ss");

    let clickKey = "choice";
    if (!lastAction || lastAction.startsWith("http") || cta === true) {
      clickKey = "CTA";
    }
    let click = {
      [clickKey]: lastAction || "Article page: Play Now",
      time: timeStr
    };
    let destination;
    if (name) {
      destination = {
        page: name,
        time: timeStr
      };
    }

    this.setState(({ clickstream }) => {
      let newClickstream;
      if (destination) {
        newClickstream = [...clickstream, click, destination];
      } else {
        newClickstream = [...clickstream, click];
      }
      return {
        clickstream: newClickstream
      };
    });
  };

  updateLastAction = lastAction => {
    this.setState({ lastAction });
  };

  pushProgress(reference) {
    const progress = {
      marketingId: this.state.marketingId,
      clickstream: this.state.clickstream
    };

    firebase
      .database()
      .ref(reference)
      .update(progress);
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    this.setMarketingId(urlParams.get("mpid"));
    this.setDatabaseRef();
  }

  componentDidUpdate() {
    this.pushProgress("/financialWellnessTwo/" + this.state.databaseKey);
  }

  render() {
    return (
      <div className={`${Structure.wrapper} `}>
        <Router primary={false}>
          <Home
            path="/"
            prevPath="/"
            nextPath="/the-cap-and-gown"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="0"
          />
          <Picture
            path="the-cap-and-gown"
            prevPath="/"
            nextPath="/making-the-move"
            dataSrc="theCapAndGown"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="1"
          />
          <Picture
            path="making-the-move"
            prevPath="/the-cap-and-gown"
            nextPath="/on-the-job"
            dataSrc="makingTheMove"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="2"
          />
          <Picture
            path="on-the-job"
            prevPath="/making-the-move"
            nextPath="/expect-the-unexpected"
            dataSrc="onTheJob"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="3"
          />
          <Picture
            path="expect-the-unexpected"
            prevPath="/on-the-job"
            nextPath="/down-the-aisle"
            dataSrc="expectTheUnexpected"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="4"
          />
          <Picture
            path="down-the-aisle"
            prevPath="/expect-the-unexpected"
            nextPath="/home-sweet-home"
            dataSrc="downTheAisle"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="5"
          />
          <Picture
            path="home-sweet-home"
            prevPath="/down-the-aisle"
            nextPath="/life-after-work"
            dataSrc="homeSweetHome"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="6"
          />
          <Picture
            path="life-after-work"
            prevPath="/home-sweet-home"
            nextPath="/bundle-of-joy"
            dataSrc="lifeAfterWork"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="7"
          />
          <Picture
            path="bundle-of-joy"
            prevPath="/life-after-work"
            nextPath="/can-you-picture"
            dataSrc="bundleOfJoy"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="8"
          />
          <CanYouPicture
            path="can-you-picture"
            prevPath="/bundle-of-joy"
            nextPath="/legal"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="9"
          />
          <Legal
            path="legal"
            prevPath="/can-you-picture"
            updateClickstream={this.updateClickstream}
            updateLastAction={this.updateLastAction}
            key="12"
          />
        </Router>
      </div>
    );
  }
}

export default App;
