import React, { Component } from "react";
import Animation from "img/mobile-animation.gif";
import Structure from "shared/styles/Structure.module.scss";

class Rotate extends Component {
  render() {
    return (
      <div className={`${Structure.message} `}>
        <h1>Please rotate your device.</h1>
        <p>This experience is optimized for landscape mode.</p>
        <img
          alt="Animation asking user to turn their screen to view content"
          src={Animation}
        />
      </div>
    );
  }
}

export default Rotate;
