import React, { Component } from "react";
import { navigate, createHistory } from "@reach/router";
import data from "shared/data.json";

import Header from "shared/components/Header.jsx";
import Rotate from "shared/components/Rotate.jsx";

import styles from "pages/Legal.module.scss";
import Structure from "shared/styles/Structure.module.scss";
import Icons from "shared/styles/Icons.module.scss";
import Typography from "shared/styles/Typography.module.scss";
import parse from "html-react-parser";

function delayUnmounting(Component) {
  return class extends React.Component {
    state = {
      shouldRender: this.props.isMounted,
    };

    componentDidUpdate(prevProps) {
      if (
        prevProps.isMounted &&
        !this.props.isMounted &&
        !this.props.activeAnimation
      ) {
        setTimeout(() => {
          this.setState({ shouldRender: false });
          if (this.props.navDirection === "up") {
            navigate(this.props.prevPath);
          }
        }, this.props.delayTime);
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true });
      }
    }

    render() {
      return this.state.shouldRender ? <Component {...this.props} /> : null;
    }
  };
}

function Content(props) {
  return (
    <div className={` ${styles.content} `}>
      <p className={Typography.legal}>{parse(data.legal.body[0])}</p>
      <p className={Typography.legal}>{parse(data.legal.body[1])}</p>
      <p className={Typography.legal}>{parse(data.legal.body[2])}</p>
      <p className={Typography.legal}>{parse(data.legal.body[3])}</p>
    </div>
  );
}

const DelayedComponent = delayUnmounting(Content);

class Legal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAnimation: true,
      isMounted: true,
    };
    this.handleNavigation = this.handleNavigation.bind(this);

    this.history = createHistory(window);
    this.history.listen((e) => {
      if (e.action === "POP") {
        window.removeEventListener("scroll", this.handleNavigation);
      }
    });
  }

  handleNavigation = (e) => {
    const window = e.currentTarget;

    if (window.pageYOffset < 1 && this.state.activeAnimation === false) {
      this.props.updateLastAction("scrolled");
      // window.console.log("scrolling up");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      // window.console.log("Stop Listening");
      this.setState((state) => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "up",
      }));
    } else if (window.pageYOffset > 1 && this.state.activeAnimation === false) {
      this.props.updateLastAction("scrolled");
      // window.console.log("scrolling down");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      // window.console.log("Stop Listening");
      this.setState((state) => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "down",
      }));
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState((state) => ({
      isMounted: true,
      contentClass: "enter",
      activeAnimation: true,
    }));
    // window.console.log("Mounted");
    this.props.updateClickstream("Legal");
    setTimeout(() => {
      // window.console.log("Start Listening");
      document.body.classList.add("active");
      window.scrollTo(0, 1);
      this.setState({ activeAnimation: false });
      window.addEventListener("scroll", this.handleNavigation);
    }, 1000);
  }

  forcePrevious = () => {
    if (!this.state.activeAnimation) {
      this.props.updateLastAction("click scrolled");
      // window.console.log("clicked up");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      // window.console.log("Stop Listening");
      this.setState((state) => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "up",
      }));
    } else if (this.state.activeAnimation) {
      // window.console.log("Animation Still In Progress");
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleNavigation);
    // window.console.log("Unmounted");
    // window.console.log("");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className={`${Structure.container} ${styles[this.state.contentClass]}`}
      >
        <span
          onClick={this.forcePrevious}
          className={` ${Icons.scrollUp} ${styles.scrollUp} `}
        />
        <Header updateClickstream={this.props.updateClickstream} />
        <Rotate />
        <DelayedComponent
          delayTime={1000}
          isMounted={this.state.isMounted}
          navDirection={this.state.navDirection}
          activeAnimation={this.state.activeAnimation}
          prevPath={this.props.prevPath}
          updateLastAction={this.props.updateLastAction}
        />
        <span className={`${Icons.scrollDown} ${styles.scrollDown}`}>
          Scroll for more
        </span>
      </div>
    );
  }
}

export default Legal;
