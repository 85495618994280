import React, { Component } from "react";

import { navigate, createHistory } from "@reach/router";
import data from "shared/data.json";
import parse from "html-react-parser";

import Header from "shared/components/Header.jsx";
import Rotate from "shared/components/Rotate.jsx";

import styles from "pages/Picture.module.scss";

import Structure from "shared/styles/Structure.module.scss";
import Icons from "shared/styles/Icons.module.scss";

function delayUnmounting(Component) {
  return class extends React.Component {
    state = {
      shouldRender: this.props.isMounted,
      shouldLeftOpen: false,
      shouldRightOpen: false,
    };

    handleLeftClick() {
      this.setState((state) => ({
        shouldLeftOpen: !this.state.shouldLeftOpen,
      }));
      this.setState((state) => ({ shouldRightOpen: false }));
      this.props.updateClickstream(
        null,
        `Left Popup: ${data[this.props.dataSrc].popUpLeft.join(" ")}`
      );
    }

    handleRightClick() {
      this.setState((state) => ({
        shouldRightOpen: !this.state.shouldRightOpen,
      }));
      this.setState((state) => ({ shouldLeftOpen: false }));
      this.props.updateClickstream(
        null,
        `Right Popup: ${data[this.props.dataSrc].popUpRight.join(" ")}`
      );
    }

    handleOutside() {
      this.setState((state) => ({
        shouldRightOpen: false,
        shouldLeftOpen: false,
      }));
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.isMounted &&
        !this.props.isMounted &&
        !this.props.activeAnimation
      ) {
        this.setState((state) => ({ shouldLeftOpen: false }));
        this.setState((state) => ({ shouldRightOpen: false }));
        setTimeout(() => {
          this.setState({ shouldRender: false });
          if (this.props.navDirection === "up") {
            navigate(this.props.prevPath);
          } else if (this.props.navDirection === "down") {
            navigate(this.props.nextPath);
          }
        }, this.props.delayTime);
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true });
      }
    }

    componentWillUnmount() {}

    render() {
      let handleLeftClick = this.handleLeftClick.bind(this);
      let handleRightClick = this.handleRightClick.bind(this);
      let handleOutside = this.handleOutside.bind(this);

      return this.state.shouldRender ? (
        <Component
          {...this.props}
          shouldLeftOpen={this.state.shouldLeftOpen}
          handleLeftClick={handleLeftClick}
          shouldRightOpen={this.state.shouldRightOpen}
          handleRightClick={handleRightClick}
          handleOutside={handleOutside}
        />
      ) : null;
    }
  };
}

function Content(props) {
  var Src = props.dataSrc;

  let dimensions = {
    width: props.width,
    height: props.height,
    top: props.offsetHeight,
    left: props.offsetWidth,
  };

  return (
    <div className={` ${styles.content} ${styles[Src]}`}>
      <h1 className={styles.title}>{data[Src].headline}</h1>

      <div className={styles.imgContainer} id="canvas">
        <div className={styles.hotSpots} style={dimensions}>
          <div
            className={` ${styles.popUp} ${styles.popUpLeft} ${
              props.shouldLeftOpen ? styles.isOpen : undefined
            } `}
          >
            <div className={styles.inner}>
              <h2>&#8220;{data[Src].popUpLeft[0]}&#8221;</h2>
              <hr />
              <p>{parse(data[Src].popUpLeft[1])}</p>
            </div>
          </div>

          <div
            className={` ${styles.popUp} ${styles.popUpRight} ${
              props.shouldRightOpen ? styles.isOpen : undefined
            } `}
          >
            <div className={styles.inner}>
              <h2>&#8220;{data[Src].popUpRight[0]}&#8221;</h2>
              <hr />
              <p>{parse(data[Src].popUpRight[1])}</p>
            </div>
          </div>

          <span
            className={` ${styles.hotSpot} ${styles.hotSpotLeft} `}
            onClick={props.handleLeftClick}
          />
          <span
            className={` ${styles.hotSpot} ${styles.hotSpotRight} `}
            onClick={props.handleRightClick}
          />
        </div>
        <div className={styles.imgMask} onClick={props.handleOutside}>
          <span className={`${styles.imgCorners}`} />
          <div className={`${styles.imgCenter}`} />
          <div className={`${styles.imgRight}`} />
          <div className={`${styles.imgLeft}`} />
          <div className={`${styles.imgBackground}`} />
          <div className={`${styles.imgBase}`} />
          <div className={`${styles.imgShadow}`} />
        </div>
      </div>

      <p className={styles.caption}>{data[Src].body[0]}</p>
      <small className={props.shouldLeftOpen ? styles.isOpen : undefined}>
        {parse(data[Src].footnotes[0])}
      </small>
      <small className={props.shouldRightOpen ? styles.isOpen : undefined}>
        {parse(data[Src].footnotes[1])}
      </small>
    </div>
  );
}
const DelayedComponent = delayUnmounting(Content);

class Picture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAnimation: true,
      isMounted: true,
      width: 0,
      height: 0,
      offsetWidth: 0,
      offsetHeight: 0,
    };
    this.handleNavigation = this.handleNavigation.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);

    this.history = createHistory(window);
    this.history.listen((e) => {
      if (e.action === "POP") {
        window.removeEventListener("scroll", this.handleNavigation);
      }
    });
  }

  updateDimensions() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      const canvasWidth = document.getElementById("canvas").offsetWidth;
      const canvasHeight = document.getElementById("canvas").offsetHeight;
      const canvasRatio = (canvasHeight * 100) / canvasWidth;

      //var baseWidth = 542;
      //var baseHeight = 363;

      //var baseRatio = (baseHeight * 100) / baseWidth;
      const baseRatio = 66.974169741697417;

      //var relativeByHeight = (baseWidth * 100) / baseHeight
      const relativeByHeight = 1.4931129476584022;

      //var relativeByWidth = (baseHeight * 100) / baseWidth;
      const relativeByWidth = 0.66974169741697417;

      // Width is 100% and Height is Unknown
      if (baseRatio > canvasRatio) {
        // window.console.log("Height=100% need Width var!");
        let estimatedWidth = canvasHeight * relativeByHeight;
        let remainingWidth = canvasWidth - estimatedWidth;
        let offsetWidthBy = remainingWidth / 2;

        this.setState({
          width: estimatedWidth,
          offsetWidth: offsetWidthBy,
          height: canvasHeight,
          offsetHeight: 0,
        });

        // window.console.log(estimatedWidth, canvasHeight, offsetWidthBy, 0);
        // Height is 100% and Width is Unknown
      } else {
        // window.console.log("Width=100% need Height var!");
        let estimatedHeight = canvasWidth * relativeByWidth;
        let remainingHeight = canvasHeight - estimatedHeight;
        let offsetHeightBy = remainingHeight / 2;

        this.setState({
          width: canvasWidth,
          offsetWidth: 0,
          height: estimatedHeight,
          offsetHeight: offsetHeightBy,
        });
        // window.console.log(canvasWidth, estimatedHeight, 0, offsetHeightBy);
      }
    }, 250);
  }

  handleNavigation = (e) => {
    const window = e.currentTarget;

    if (window.pageYOffset < 1 && this.state.activeAnimation === false) {
      this.props.updateLastAction("scrolled");
      // window.console.log("scrolling up");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      // window.console.log("Stop Listening");
      this.setState((state) => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "up",
      }));
    } else if (window.pageYOffset > 1 && this.state.activeAnimation === false) {
      this.props.updateLastAction("scrolled");
      // window.console.log("scrolling down");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      // window.console.log("Stop Listening");
      this.setState((state) => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "down",
      }));
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.setState((state) => ({
      isMounted: true,
      contentClass: "enter",
      activeAnimation: true,
    }));
    // window.console.log("Mounted");
    this.props.updateClickstream(data[this.props.dataSrc].headline);
    setTimeout(() => {
      // window.console.log("Start Listening");
      document.body.classList.add("active");
      window.scrollTo(0, 1);
      this.setState({ activeAnimation: false });
      window.addEventListener("scroll", this.handleNavigation);
    }, 2500);
  }

  forcePrevious = () => {
    if (!this.state.activeAnimation) {
      this.props.updateLastAction("click scrolled");
      // window.console.log("clicked up");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      // window.console.log("Stop Listening");
      this.setState((state) => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "up",
      }));
    } else if (this.state.activeAnimation) {
      // window.console.log("Animation Still In Progress");
    }
  };

  forceNext = () => {
    if (!this.state.activeAnimation) {
      this.props.updateLastAction("click scrolled");
      // window.console.log("clicked down");
      window.removeEventListener("scroll", this.handleNavigation);
      document.body.classList.remove("active");
      // window.console.log("Stop Listening");
      this.setState((state) => ({
        isMounted: false,
        contentClass: "exit",
        navDirection: "down",
      }));
    } else if (this.state.activeAnimation) {
      // window.console.log("Animation Still In Progress");
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("scroll", this.handleNavigation);
    // window.console.log("Unmounted");
    // window.console.log("");
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className={`${Structure.container} ${
          Structure[this.state.contentClass]
        } ${styles[this.state.contentClass]} `}
      >
        <span onClick={this.forcePrevious} className={Icons.scrollUp} />
        <Header updateClickstream={this.props.updateClickstream} />
        <Rotate />
        <DelayedComponent
          delayTime={2500}
          isMounted={this.state.isMounted}
          navDirection={this.state.navDirection}
          activeAnimation={this.state.activeAnimation}
          nextPath={this.props.nextPath}
          prevPath={this.props.prevPath}
          dataSrc={this.props.dataSrc}
          width={this.state.width}
          offsetWidth={this.state.offsetWidth}
          height={this.state.height}
          offsetHeight={this.state.offsetHeight}
          updateClickstream={this.props.updateClickstream}
        />
        <span onClick={this.forceNext} className={Icons.scrollDown}>
          Scroll for more
        </span>
      </div>
    );
  }
}
export default Picture;
